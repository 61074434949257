import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/home/close.png'


const _withScopeId = n => (_pushScopeId("data-v-2612ac72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "activity"
}
const _hoisted_5 = { class: "activity-box" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_component_detail = _resolveComponent("component-detail")!
  const _component_component_open = _resolveComponent("component-open")!
  const _component_component_risk_board = _resolveComponent("component-risk-board")!
  const _component_component_pie = _resolveComponent("component-pie")!
  const _component_insurance_money = _resolveComponent("insurance-money")!
  const _component_components_team = _resolveComponent("components-team")!
  const _component_components_file = _resolveComponent("components-file")!
  const _component_components_hotline = _resolveComponent("components-hotline")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_component_detail),
      _createVNode(_component_component_open),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_component_risk_board, { style: {"width":"67%","margin-right":"20px","height":"400px"} }),
        _createVNode(_component_component_pie, { style: {"width":"33%","height":"400px"} })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_insurance_money, { style: {"width":"67%","margin-right":"20px","height":"338px"} }),
        _createVNode(_component_components_team, { style: {"width":"33%","height":"338px"} })
      ]),
      _createVNode(_component_components_file)
    ]),
    (_ctx.activityImg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.activityClick && _ctx.activityClick(...args))),
              src: _ctx.activityImg,
              class: "activity-img"
            }, null, 8, _hoisted_6),
            _createElementVNode("img", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activityImg = '')),
              src: _imports_0,
              class: "activity-close"
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_components_hotline)
  ], 64))
}