
import { defineComponent } from 'vue'
import { getCommonlyFile, CommonlyFileDto } from '@/api/home'
export default defineComponent({
    name: 'HomeDetail',
    data () {
        return {
            data: {} as CommonlyFileDto[]
        }
    },
    async created () {
        const result = await getCommonlyFile()
        this.data = result
    }
})
