
import { defineComponent } from 'vue'
import * as echarts from 'echarts'
import { getInsuranceTrendDay, getInsuranceTrendMonth } from '@/api/insurance'
export default defineComponent({
    name: 'HomeInsurance',
    data () {
        return {
            month: {
                xAxisData: [] as string[],
                seriesData: [] as string[]
            },
            day: {
                xAxisData: [] as string[],
                seriesData: [] as string[]
            },
            chart: null as echarts.ECharts | null
        }
    },
    async mounted () {
        echarts.dispose(document.getElementById('trend') as HTMLElement)
        this.chart = echarts.init(
            document.getElementById('trend') as HTMLElement
        )
        this.chart.setOption({
            color: ['#2EA1FF'],
            xAxis: {
                type: 'category',
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false,
                    alignWithLabel: true
                }
            },
            yAxis: {
                type: 'value',
                boundaryGap: ['0%', '10%']
            },
            series: [
                {
                    type: 'bar',
                    barWidth: 20
                }
            ],
            tooltip: {
                show: true
            },
            grid: {
                x: 50,
                y: 20,
                x2: 50,
                y2: 60
            }
        })
        await this.onMonth()
    },
    methods: {
        async onMonth () {
            if (this.month.xAxisData.length === 0) {
                const result = await getInsuranceTrendMonth()
                this.month.xAxisData = result.month
                this.month.seriesData = result.premium
            }
            if (this.chart) {
                this.chart.setOption({
                    xAxis: {
                        data: this.month.xAxisData
                    },
                    series: [
                        {
                            data: this.month.seriesData
                        }
                    ]
                })
            }
            const span = document.querySelectorAll('.tab span')
            span.forEach((item) => {
                item.classList.remove('active')
            })
            span[0].classList.add('active')
        },
        async onDay () {
            if (this.day.xAxisData.length === 0) {
                const result = await getInsuranceTrendDay()
                this.day.xAxisData = result.date
                this.day.seriesData = result.premium
            }
            if (this.chart) {
                this.chart.setOption({
                    xAxis: {
                        data: this.day.xAxisData
                    },
                    series: [
                        {
                            data: this.day.seriesData
                        }
                    ]
                })
            }
            const span = document.querySelectorAll('.tab span')
            span.forEach((item) => {
                item.classList.remove('active')
            })
            span[1].classList.add('active')
        }
    }
})
