
import { defineComponent } from 'vue'
import { getTeamMessage, TeamMessageDto } from '@/api/message'
export default defineComponent({
    name: 'HomeTeam',
    data () {
        return {
            list: {} as TeamMessageDto[]
        }
    },
    async created () {
        const result = await getTeamMessage()
        this.list = result
    },
    methods: {
        more () {
            this.$router.push({
                path: '/insurance/plan'
            })
        }
    }
})
