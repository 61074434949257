
import { defineComponent } from 'vue'
import detail from './components/detail.vue'
import open from './components/open.vue'
import pie from './components/pie.vue'
import riskBoard from './components/risk-board.vue'
import insuranceMoney from './components/insurance-money.vue'
import team from './components/team.vue'
import file from './components/file.vue'
import hotline from './components/hotline.vue'
import { getNewActivity } from '@/api/home'
export default defineComponent({
    name: 'Home',
    components: {
        'component-detail': detail,
        'component-open': open,
        'component-risk-board': riskBoard,
        'component-pie': pie,
        'insurance-money': insuranceMoney,
        'components-team': team,
        'components-file': file,
        'components-hotline': hotline
    },
    data () {
        return {
            activityImg: '',
            activityUrl: ''
        }
    },
    async created () {
        if (sessionStorage.isActivity) {
            return
        }
        const activity = await getNewActivity({
            source: 'pc'
        })
        this.activityImg = activity?.image
        this.activityUrl = activity?.url
        sessionStorage.isActivity = true
    },
    methods: {
        activityClick () {
            if (this.activityUrl) {
                if (this.activityUrl.startsWith('http')) {
                    window.open(this.activityUrl, '_blank')
                } else {
                    this.$router.push(this.activityUrl)
                }
            }
        }
    }
})
