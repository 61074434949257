
import { defineComponent } from 'vue'
import InsurancePlan from '../../components/insurance-plan.vue'
export default defineComponent({
    name: 'HomeOpen',
    components: { 'insurance-plan': InsurancePlan },
    methods: {
        more () {
            this.$router.push({
                path: '/insurance/plan'
            })
        }
    }
})
