
import { defineComponent } from 'vue'
import { getBasicData, getAd, BasicDataDto } from '@/api/home'

export default defineComponent({
    name: 'HomeDetail',
    data () {
        return {
            data: {} as BasicDataDto,
            ad: [],
            adShow: false
        }
    },
    async created () {
        const result = await getBasicData()
        this.data = result
        const adRes = await getAd({ source: 'pc' })
        this.ad = adRes
        if (adRes.length > 0) {
            this.adShow = true
        }
    },
    methods: {
        jumpInfo (path:string) {
            window.open(path, '_blank')
        }
    }
})
