
import { defineComponent } from 'vue'
import { getInsuranceOpenData, InsuranceOpenDto } from '@/api/insurance'
export default defineComponent({
    name: 'InsurancePlanComponent',
    props: ['nodata', 'withoutClosed'],
    data () {
        return {
            data: {} as InsuranceOpenDto[]
        }
    },
    async created () {
        const result = await getInsuranceOpenData({
            withoutClosed: this.withoutClosed
        })
        this.data = result
    },
    methods: {}
})
