
import { defineComponent } from 'vue'
import { getHotline } from '@/api/home'

export default defineComponent({
    name: 'HomeHotline',

    data () {
        return {
            isHovered: false,
            phoneNumber: ''
        }
    },
    async mounted () {
        const result = await getHotline()
        if (result.number) {
            this.phoneNumber = result.number
        }
    },
    methods: {
        toggleHover (status: boolean) {
            this.isHovered = status
        }

    }
})
