
import { defineComponent } from 'vue'
import {
    getRiskAnalysisData, RiskAnalysisDto
} from '@/api/home'
import * as echarts from 'echarts'
export default defineComponent({
    name: 'RiskBoard',
    data () {
        return {
            riskInfo: {} as RiskAnalysisDto,
            isEmpty: false
        }
    },
    async mounted () {
        await this.getData()
    },
    methods: {
        // 渲染数据
        async getData () {
            echarts.dispose(document.getElementById('chart-board') as HTMLElement)
            const result = await getRiskAnalysisData()
            if (!result || typeof result.rank === 'undefined') {
                this.isEmpty = true
            } else {
                this.isEmpty = false
            }
            const configData = this.getChartsConfig()
            const score = result.score >= 100 ? 99 : result.score
            configData.series[0].data[0].value = score
            configData.series[1].data[0].value = score - 1
            this.riskInfo = result
            const charts = echarts.init(document.getElementById('chart-board') as HTMLElement)
            charts.setOption(configData)
        },

        // 仪表盘数据
        getChartsConfig () {
            return {
                series: [
                    {
                        type: 'gauge',
                        center: ['50%', '90%'],
                        startAngle: 180,
                        endAngle: 0,
                        radius: '130%',
                        min: 0,
                        max: 100,
                        splitNumber: 20,
                        itemStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: '#FF7E68' // 0% 处的颜色
                                }, {
                                    offset: 0.25, color: '#FF9444' // 100% 处的颜色
                                }, {
                                    offset: 0.5, color: '#FFC53D' // 100% 处的颜色
                                }, {
                                    offset: 0.75, color: '#B0ED00' // 100% 处的颜色
                                }, {
                                    offset: 1, color: '#00D679' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        progress: {
                            show: true,
                            width: 60
                        },
                        pointer: {
                            // icon: 'path://M2.9,0.7L2.9,0.7c1.4,0,2.6,1.2,2.6,2.6v115c0,1.4-1.2,2.6-2.6,2.6l0,0c-1.4,0-2.6-1.2-2.6-2.6V3.3C0.3,1.9,1.4,0.7,2.9,0.7z',
                            show: false,
                            length: '130',
                            offsetCenter: [0, -235],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                width: 35,
                                color: [
                                    [1, new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                                        {
                                            offset: 0.3,
                                            color: 'rgba(255, 129, 114, 0.1)'
                                        },
                                        {
                                            offset: 0.7,
                                            color: 'rgba(255, 197, 61, 0.1)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(115, 222, 179, 0.1)'
                                        }
                                    ])
                                    ]
                                ]
                            }
                        },
                        axisTick: {
                            distance: -50,
                            splitNumber: 5,
                            lineStyle: {
                                width: 4,
                                height: 3,
                                color: '#FFF3E1'
                            }
                        },
                        splitLine: {
                            distance: -45,
                            length: 14,
                            show: false,
                            lineStyle: {
                                width: 3,
                                height: 2,
                                color: '#FFF3E1'
                            }
                        },
                        axisLabel: {
                            distance: -30,
                            color: '#999',
                            fontSize: 20,
                            show: true,
                            formatter: function (value:any) {
                                if (value === 0) {
                                    return ['{a|极差}'].join('\n')
                                } else if (value === 25) {
                                    return ['{b|较差}'].join('\n')
                                } else if (value === 50) {
                                    return ['{c|一般}'].join('\n')
                                } else if (value === 75) {
                                    return ['{d|不错}'].join('\n')
                                } else if (value === 100) {
                                    return ['{e|优秀}'].join('\n')
                                }
                            },
                            rich: {
                                a: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#FF7E68',
                                    align: 'center',
                                    margin: 100
                                },
                                b: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#FF9444',
                                    align: 'center'
                                },
                                c: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#FFC53D',
                                    align: 'center'
                                },
                                d: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#B0ED00',
                                    align: 'center'
                                },
                                e: {
                                    fontWeight: 600,
                                    fontSize: 20,
                                    color: '#00D679',
                                    align: 'center',
                                    margin: 100
                                }
                            }
                        },

                        anchor: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            valueAnimation: true,
                            width: '100%',
                            height: '50%',
                            lineHeight: 60,
                            borderRadius: 8,
                            offsetCenter: [0, '-40%'],
                            fontSize: 60,
                            fontWeight: 'bolder',
                            formatter: function (value:any) {
                                if (value <= 20) {
                                    return ['{a|}', '{b|}', '{a|极差}', '{b|综合评价}'].join('\n')
                                } else if (value > 20 && value <= 40) {
                                    return ['{a|}', '{b|}', '{a|较差}', '{b|综合评价}'].join('\n')
                                } else if (value > 40 && value <= 60) {
                                    return ['{a|}', '{b|}', '{a|一般}', '{b|综合评价}'].join('\n')
                                } else if (value > 60 && value <= 80) {
                                    return ['{a|}', '{b|}', '{a|不错}', '{b|综合评价}'].join('\n')
                                } else if (value > 80 && value <= 100) {
                                    return ['{a|}', '{b|}', '{a|优秀}', '{b|综合评价}'].join('\n')
                                }
                            },
                            rich: {
                                a: {
                                    fontSize: 30,
                                    fontWeight: 600,
                                    fontFamily: 'Arial',
                                    lineHeight: 60,
                                    margin: [250, 0, 0, 0],
                                    color: '#202536',
                                    align: 'center'
                                },
                                b: {
                                    fontSize: 14,
                                    fontWeight: 400,
                                    fontFamily: 'Arial',
                                    lineHeight: -100,
                                    color: '#9497B1',
                                    align: 'center'
                                }
                            },
                            backgroundColor: {
                                width: '1%',
                                height: '1%',
                                image: 'https://s1.ax1x.com/2022/11/10/z9u9w4.png'
                            }
                        },
                        data: [
                            {
                                value: 81
                            }
                        ]
                    },
                    {
                        type: 'gauge',
                        center: ['50%', '90%'],
                        startAngle: 180,
                        radius: '113%',
                        animation: false,
                        endAngle: 0,
                        min: 0,
                        max: 100,
                        itemStyle: {
                            color: '#fff'
                        },
                        progress: {
                            show: true,
                            width: 45
                        },
                        pointer: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        detail: {
                            show: false
                        },
                        data: [
                            {
                                value: 80
                            }
                        ]
                    }
                ]
            }
        }
    }

})
