
import { defineComponent } from 'vue'
import {
    getOccupationLevelPieStatistics
} from '@/api/insurance'
import * as echarts from 'echarts'
export default defineComponent({
    name: 'LevelPie',
    data () {
        return {
            isEmpty: false,
            onlyActive: true
        }
    },
    async mounted () {
        this.handleClick()
    },
    methods: {
        onIsActive (isActive: boolean) {
            this.onlyActive = isActive
            this.handleClick()
        },
        // 渲染数据
        async handleClick () {
            echarts.dispose(document.getElementById('chart-level') as HTMLElement)
            const result = await getOccupationLevelPieStatistics({
                onlyActive: this.onlyActive ? '1' : ''
            })
            if (result.length === 0) {
                this.isEmpty = true
            } else {
                this.isEmpty = false
            }
            const charts = echarts.init(
                document.getElementById('chart-level') as HTMLElement
            )
            charts.setOption({
                tooltip: {
                    trigger: 'item'
                },
                color: ['#00D679', '#FAC858', '#EE6666'],
                grid: {
                    top: 0
                },
                legend: {
                    bottom: '0',
                    left: 'center',
                    formatter: (str: string) => {
                        if (str.length > 5) {
                            return str.slice(0, 5) + '...'
                        } else {
                            return str
                        }
                    }
                },
                series: [
                    {
                        name: '风险等级',
                        type: 'pie',
                        radius: ['35%', '60%'],
                        center: ['50%', '45%'],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                position: 'inside',
                                formatter: '{c}',
                                color: '#fff',
                                fontSize: 14,
                                fontWeight: 700
                            }
                        },
                        toolbox: {
                            show: true
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '20',
                                fontWeight: 'bold'
                            },
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        },
                        data: result.map(item => {
                            return {
                                name: item.name,
                                value: item.count
                            }
                        })
                    }
                ]
            })
        }
    }
})
